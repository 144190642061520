import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useContext,
} from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { FormattedMessage, injectIntl } from "react-intl";


import swal from "sweetalert";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";


import {
  FiEdit,
  FiEye,
  FiArrowLeft,
  FiPlus,
  FiFilter,
  FiXCircle,
  FiTrash2,
  FiAlertCircle
} from "react-icons/fi";
import { AiOutlineFolderOpen, AiOutlineClockCircle, AiOutlineMail } from "react-icons/ai";
import { FaEllipsisH, FaSortAmountUp, FaStickyNote, FaRegStickyNote, FaList, FaHistory } from "react-icons/fa";
import { MdExpandMore, MdExpandLess, MdAttachMoney, MdFavorite } from "react-icons/md";
import { ImCross, ImCancelCircle } from "react-icons/im";
import iconEspelhoCliente from "./../../../../../assets/images/icone-extrato1.png";
import iconEspelhoDistribuidor from "./../../../../../assets/images/icone-extrato2.png";

import { Container, ActionContainer, ContainerPedidoLink, ContainerButton, FilterContainer } from "./styles";
import api from "./../../../../../services/api";
import UploadNFServico from "./../../../../../components/UploadNFServico";
import UploadNFDistribuidor from "./../../../../../components/UploadNFDistribuidor";
import DownloadBoleto from "./../../../../../components/DownloadBoleto";
import ButtonIcon from "./../../../../../components/ButtonIcon";
import Button from "./../../../../../components/ButtonDefault";
import InputMaskDefault from "./../../../../../components/InputMaskDefault";
import ButtonImage from "./../../../../../components/ButtonImage";
import OptionsList from "./../../../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../../../components/OptionsList/OptionsIconPlaceholder";
import InputLabel from "./../../../../../components/InputLabel";
import ListSelect from "./../../../../../components/ListSelect";
import ContentSpinner from "./../../../../../components/Spinner/Content";
import AsyncListSelect from "./../../../../../components/ListSelect/AsyncListSelect";

import ExcelLogo from "./../../../../../assets/images/logo-excel.png";

import Paginate from "./../../../../../components/Paginate";

import { toast } from "react-toastify";
import ReactExport from "react-data-export";
import AuthContext from "./../../../../../context/auth";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  flexRender,
} from '@tanstack/react-table'


import { useDrag, useDrop } from 'react-dnd'

const defaultColumns = [
  {
    accessorKey: 'expander',
    id: 'expander',
    size: 75,
    header: ({ table }) => (
      <>
        <span
          {...{
            onClick: table.getToggleAllRowsExpandedHandler(),
          }}
        >
          {table.getIsAllRowsExpanded() ? <MdExpandLess /> : <MdExpandMore/>}
        </span>

      </>
    ),
    cell: ({ row, getValue}) => (
      <div>
        <>
          {getValue()}

          {row.getCanExpand() ? (
            <span
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              {row.getIsExpanded() ? <MdExpandLess /> : <MdExpandMore/>}
            </span>
          ) : (
            <>
            </>
          )}
        </>
      </div>
    ),
  },
  {
    accessorKey: 'status',
    id: 'status',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="statusPedidos" />,
  },

  {
    accessorKey: 'statusCotacao',
    id: 'statusCotacao',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="statusCotacao" />,
  },

  {
    accessorKey: 'dataPedido',
    id: 'dataPedido',
    size: 140,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="dataCriacao" />,
  },

  {
    accessorKey: 'cliente',
    id: 'cliente',
    size: 250,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="cliente" />,
  },

  {
    accessorKey: 'marca',
    id: 'marca',
    size: 150,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="marca" />,
  },

  {
    accessorKey: 'categoria',
    id: 'categoria',
    size: 150,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="categoria" />,
  },

  {
    accessorKey: 'produto',
    id: 'produto',
    size: 150,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="produtos" />,
  },

  {
    accessorKey: 'tipoUnitario',
    id: 'tipoUnitario',
    size: 150,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="tipoUnitario" />,
  },

  {
    accessorKey: 'quantidade',
    id: 'quantidade',
    size: 150,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="quantidade" />,
  },

  {
    accessorKey: 'totalPedidoAprovado',
    id: 'totalPedidoAprovado',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Valor Total Pedido",
  },

  {
    accessorKey: 'valorTotal',
    id: 'valorTotal',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="volumeM2" />,
  },

  {
    accessorKey: 'preco',
    id: 'preco',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="preco" />,
  },

  {
    accessorKey: 'distribuidorTRR',
    id: 'distribuidorTRR',
    size: 250,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="distribuidores" />,
  },

  {
    accessorKey: 'integrado',
    id: 'integrado',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="integrado" />,
  },

  ////////////////////////////////////////////////

  {
    accessorKey: 'notaServico',
    id: 'notaServico',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="notaServiço" />,
  },
  {
    accessorKey: 'notaDistribuidor',
    id: 'notaDistribuidor',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="notaDistribuidor" />,
  },
  {
    accessorKey: 'boleto',
    id: 'boleto',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="boleto" />,
  },
  {
    accessorKey: 'historico',
    id: 'historico',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="historicoNegociacao" />,
  },

  /////////////////////////////////////////////

  {
    accessorKey: 'prazoPagamento',
    id: 'prazoPagamento',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="prazoPagamento" />,
  },
  {
    accessorKey: 'dataEntrega',
    id: 'dataEntrega',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Data Entrega",
  },
  {
    accessorKey: 'prazoEntrega',
    id: 'prazoEntrega',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Previsão Entrega",
  },
  {
    accessorKey: 'reemsolso',
    id: 'reemsolso',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Data Reembolso",
  },

  /////////////////////////////////////////////////
  {
    accessorKey: 'numeroPedido',
    id: 'numeroPedido',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="numPedidoSistema" />,
  },
  {
    accessorKey: 'cotacaoOriginal',
    id: 'cotacaoOriginal',
    size: 120,
    cell: info => info.getValue(),
    header: () =>  "Nº Cotação Incompleta",
  },
  {
    accessorKey: 'numeroPedidoWizeo',
    id: 'numeroPedidoWizeo',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Carga Wizeo",
  },
  {
    accessorKey: 'programados',
    id: 'programados',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Gerou Programação",
  },
  {
    accessorKey: 'operador',
    id: 'operador',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="operador" />,
  },
  {
    accessorKey: 'criador',
    id: 'criador',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="criador" />,
  },
  {
    accessorKey: 'responsavel',
    id: 'responsavel',
    size: 120,
    cell: info => info.getValue(),
    header: () => "Responsável Cotação",
  },
  {
    accessorKey: 'numeroCotacao',
    id: 'numeroCotacao',
    size: 120,
    cell: info => info.getValue(),
    header: () => <FormattedMessage id="numeroCotacao" />,
  },
]


const PainelList = ({ intl }) => {

  const [expanded, setExpanded] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({
    notaServico: false, notaDistribuidor: false, boleto: false, historico: false,

    prazoPagamento: false, dataEntrega: false, reemsolso: false, prazoEntrega: false,

    numeroPedido: false, cotacaoOriginal: false, numeroPedidoWizeo: false, programados: false, operador: false, criador: false, responsavel: false, numeroCotacao: false,

    marca: false, categoria: false, tipoUnitario: false, quantidade: false,
  });
  const [exibirPrazo, setExibirPrazo] = useState(false);
  const [exibirFiscais, setExibirFiscais] = useState(false);
  const [exibirInfos, setExibirInfos] = useState(false);

  const [aprovadoFilter, setAprovadoFilter] = useState(false);
  const [analiseMesaFilter, setAnaliseMesaFilter] = useState(false);
  const [renegociacaoFilter, setRenegociacaoFilter] = useState(false);
  const [outrosFilter, setOutrosFilter] = useState(false);

  const [data, setData] = useState([]);
  const [columns] = useState(() => [...defaultColumns])
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id)
  )

  registerLocale("ptBR", ptBR);

  /* Data Criação */
  const [DateCreateRange, setDateRange] = useState([null, null]);
  const [startDateCreate, endDateCreate] = DateCreateRange;

  /* Data Entrega */
  const [DateEntregaRange, setEntregaRange] = useState([null, null]);
  const [startDateEntrega, endDateEntrega] = DateEntregaRange;


  // Estados da lista painel
  const [check, setCheck] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [integrateButton, setIntegrateButton] = useState(false);
  const [filterButton, setFilterButton] = useState(false);
  const [integrateMultiButtonLoading, setIntegrateMultiButtonLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingListFilter, setLoadingListFilter] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingEspelhoCliente, setLoadingEspelhoCliente] = useState(false);
  const [loadingEspelhoDistribuidor, setLoadingEspelhoDistribuidor] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [showLegends, setShowLegends] = useState(false);
  const [filterNumeroPedido, setFilterNumeroPedido] = useState("");
  const [filterNumeroPedidoCliente, setFilterNumeroPedidoCliente] = useState("");
  const [filterCliente, setFilterCliente] = useState("");
  const [filterOperador, setFilterOperador] = useState("");
  const [filterProduto, setFilterProduto] = useState("");
  const [filterDistribuidorTRR, setFilterDistribuidorTRR] = useState("");
  const [filterPrazoPagamento, setFilterPrazoPagamento] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStatusCotacao, setFilterStatusCotacao] = useState("");
  const [filterIntegrado, setFilterIntegrado] = useState("");
  const [filterCargaWizeo, setFilterCargaWizeo] = useState("");
  const [filterCotacao, setFilterCotacao] = useState("");
  const [filterNotaServiço, setFilterNotaServiço] = useState("");
  const [filterNotaDitribuidor, setFilterNotaDistribuidor] = useState("");
  const [filterBoleto, setFilterBoleto] = useState("");
  const [filterProg, setFilterProg] = useState("");
  const [filterCotacaoOriginal, setFilterCotacaoOriginal] = useState("");

  // Paginação
  const location = useLocation();
  const [totalCotacao, setTotalCotacao] = useState("");
  const [totalPedido, setTotalPedido] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const history = useHistory();

  const refExcel = createRef(null);

  const { user } = useContext(AuthContext);

  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  const integradoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
    { value: 2, label: <FormattedMessage id="Erro" /> },
  ];

  const notaServiço = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const notaDistribuidor = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const boleto = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const statusOptions = [
    { value: "cotacao", label: <FormattedMessage id="cotacao" /> },
    {
      value: "Aguardando Aprovação",
      label: <FormattedMessage id="aguardandoAprovacao" />,
    },
    { value: "aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
    {
      value: "Aguardando Faturamento",
      label: <FormattedMessage id="aguardando" />,
    },
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const statusCotacao = [
    {
      value: "Análise Cliente",
      label: <FormattedMessage id="analiseCliente" />,
    },
    { value: "Análise Distribuidor/TRR", label: "Análise Distribuidor/TRR" },
    { value: "Análise Mesa de Operação", label: "Análise Mesa de Operação" },
    {
      value: "Reanálise Distribuidor/TRR",
      label: <FormattedMessage id="reanalise" />,
    },
    { value: "Aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "Reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const handleHistory = useCallback(
    (pedido, cotacaoIdProduto) => {
      const data = {
        pedido,
        cotacaoIdProduto
      };

      history.push("/orders/painel/history", data);
    },
    [history]
  );


  const PedidoLink = useCallback(({ item, menorPreco }) => {
    return (
      <ContainerPedidoLink>
        <Link
          to={{
            pathname: "/orders/painel/show",
            state: {
              numeroPedido: item?.numero_cotacao_sistema,
              pedido: item?.pedido,
              id: item?.pedido_id,
              id_cotacao: item?.id,
              original: item,
              cotacaoIdProduto: menorPreco
            },
          }}
        >
          {item?.numero_cotacao_sistema}
          {item?.numero_cotacao_sistema && <AiOutlineFolderOpen />}
        </Link>
      </ContainerPedidoLink>
    );
  }, []);



  //Pedidos OBJ
  useEffect(() => {
    (async () => {
      setLoadingList(true);


      // const [columnOrder, setColumnOrder] = useState(
      //   columns.map(column => column.id)
      // )


      let storedArrayColumn = localStorage.getItem("columnOrder");

      console.log(storedArrayColumn)

      if (storedArrayColumn){
        const newColumnOrder = JSON.parse(storedArrayColumn);
        setColumnOrder(newColumnOrder);
      }



      localStorage.getItem("dataFilterStorage")
      let storedArray = localStorage.getItem("dataFilterStorage");
      const dataFilterStorage = JSON.parse(storedArray);


      try {
        let dataFilter = {}

        if (history.location?.state?.statusCard === "Análise Mesa de Operação" || history.location?.state?.statusCard === "Análise Distribuidor/TRR") {
        // statusCard vem o status do card em HOME
          const status = history.location?.state?.statusCard
          dataFilter = {
            status_filtro_cotacao: status,
            "order_by[campo]": "id",
            "order_by[order]": "desc",
            data_criacao_de: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            data_criacao_ate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
           limit: 40,
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

        } else if (history.location?.state?.statusCard === "Faturado") {
          const status = history.location?.state?.statusCard

          dataFilter = {
            status_filtro_cotacao: status,
            "order_by[campo]": "id",
            "order_by[order]": "desc",
            data_faturamento_de: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            data_faturamento_ate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
            limit: 40,
            tipo_produto: "combustivel"
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

        } else if (history.location?.state?.painelControle) {
          // painelControle vem de todos botões de voltar que englobam o pedido
          dataFilter = dataFilterStorage;

        } else {
          dataFilter = {
            limit: 40,
            data_criacao_de: moment().format("YYYY-MM-DD 00:00:00"),
            data_criacao_ate: moment().format("YYYY-MM-DD 23:59:59"),
            "order_by[campo]": "id",
            "order_by[order]": "desc",
            tipo_produto: "combustivel"
          };
          localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));
        }

        const { data: response } = await api.get("/pedido/filter", {
          params: dataFilter,
        });

        let totalCotação = 0;
        let totalPedido = 0;

        response.dados.data.forEach((pedido) => {
          totalPedido = totalPedido + 1;
          let pedidoRow = {};
          let cotacoesRows = [];
          let obj = {};
          let cotacaoIdProduto = [];
          let menorPreco = [];

          let produtoFormatted = [];
          produtoFormatted = pedido?.produtos_pedido.map((prod) => {
            return prod.descricao;
          });

          //Integração Status
          let integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
          let integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
          let integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

          let cotacaoMap = [];
          if( integradoSim.length > 0 ){
            cotacaoMap['integrado'] = "Sim"
          }else if(integradoNao.length > 0 && integradoSim.length === 0){
            cotacaoMap['integrado'] = "Não"
          }else if(integradoErro.length > 0){
            cotacaoMap['integrado'] = "Erro"
          }

          let idCotacaoIntegrar = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.status_cotacao === "Aprovado"){
              idCotacaoIntegrar = cotacao.id;
            }
          })

          let numeroCargaWizeo = "";

          pedido.cotacoes.forEach((cotacao) => {
            if (cotacao.numero_pedido_sodexo !== null){
              numeroCargaWizeo = cotacao.numero_pedido_sodexo
            }
          })

          let totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              let prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

          // Cria objeto com os pedidos
          Object.assign(pedidoRow, {
            original: pedido,
            id: pedido.id,
            id_cotacao: idCotacaoIntegrar,
            numeroPedidoSodexo: numeroCargaWizeo,
            numeroPedidoWizeo: numeroCargaWizeo,
            numeroPedido: pedido.numero_pedido_sistema,
            numeroCotacao: "",
            cotacaoOriginal: "",
            totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            ),
            numeroPedidoCliente: pedido.numero_pedido_cliente,
            dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
            dataSemFormatacao: pedido.created_at,
            cliente:
              pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              pedido.cotacoes[0].cliente?.razao_social,
            produto: "",
            distribuidorTRR: "",
            prazoPagamento: pedido?.prazo,
            dataEntrega: format(
              parseISO(pedido.data_entrega_desejada),
              "dd/MM/yyyy"
            ),
            prazoEntrega: "",
            status: pedido?.status_pedido,
            statusCotacao: "",
            valorTotal: "",
            integrado: cotacaoMap['integrado'],
            operador: pedido?.operador?.name,
            criador: pedido?.criado_por?.name,
            responsavel: "",
            historico: (
              <FaHistory
                size={18}
                onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
              />
            ),
            notaServico: (
              <UploadNFServico
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_cliente_nome}
              />
            ),
            notaDistribuidor: (
              <UploadNFDistribuidor
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasNF={pedido?.cotacao_faturada[0]?.nota_distribuidor_nome}
              />
            ),
            boleto: pedido?.cotacao_faturada[0]?.boleto_cliente_nome && (
              <DownloadBoleto
                idCotacao={pedido?.cotacao_faturada[0]?.id}
                hasBoleto={pedido?.cotacao_faturada[0]?.boleto_cliente_nome}
              />
            ),
            programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
            cotacaoOriginalExcel: "",
            produtoExcel: produtoFormatted.toString(),
            valorTotalExcel: "",
            distribuidor_id: "",
            distribuidor_preferencia_id: "",
          });

          pedido.cotacoes.forEach((cotacao) => {
            totalCotação = totalCotação + 1;
            let arrayQuantidadeTotal = [];
            let valorTotalFormatted = 0;

            arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
              return parseFloat(prod.quantidade / 1000)
                .toFixed(2)
                ?.replace(".", ",");
            });

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            // Cria objeto com as Cotações
            cotacao.produtos_cotacao.map((prod) => {
              obj = {};
              Object.assign(obj, {
                original: pedido,
                id: pedido.id,
                id_cotacao: cotacao.id,
                numeroPedidoSodexo:"",
                numeroPedidoWizeo: "",
                numeroPedido: "",
                numeroCotacao: cotacao.numero_cotacao_sistema,
                cotacaoOriginal: (
                  <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />
                ),
                numeroPedidoCliente: pedido.numero_pedido_cliente,
                dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
                dataSemFormatacao: pedido.created_at,
                cliente: "",
                produto: prod?.produto?.descricao,
                cotacao_produto_id: prod?.id,
                valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
                preco: ((parseFloat(prod?.valor_unitario) +  parseFloat(prod?.valor_frete)).toFixed(4)).toString(),
                distribuidorTRR: cotacao.distribuidor
                  ? cotacao?.distribuidor.cpf_cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    ) +
                    " - " +
                    cotacao?.distribuidor.razao_social
                  : "",
                prazoPagamento: "",
                dataEntrega: "",
                prazoEntrega: prod?.prazo_entrega,
                status: pedido?.status_pedido,
                statusCotacao: cotacao?.status_cotacao,
                integrado: "",
                operador: "",
                criador: "",
                responsavel: cotacao?.ultimo_editor_preco?.name,
                historico: "",
                notaServico: "",
                notaDistribuidor: "",
                boleto: "",
                programados: "",
                cotacaoOriginalExcel:
                  cotacao?.cotacao_original?.numero_cotacao_sistema,
                produtoExcel: produtoFormatted.toString(),
                valorTotalExcel: valorTotalFormatted.toString(),
                distribuidor_id: cotacao?.distribuidor?.id,
                distribuidor_preferencia_id:
                  cotacao?.cliente?.distribuidor_preferencia_id,
                cotacaoIdProduto: cotacaoIdProduto
              });

              cotacoesRows.push(obj);
              return prod;
            });
          });

          // Ordena Cotação por produto e preço
          cotacoesRows = cotacoesRows.sort((a, b) => {
            function value(el) {
              let x = el;
              return x === "0.0000" ? Infinity : x;
            }

            return (
              a.produto.localeCompare(b.produto) ||
              value(a.preco) - value(b.preco)
            );
          });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }

            return i;
          });

          Object.assign(pedidoRow, {
            subRows: cotacoesRows,
          });

          setTotalCotacao(totalCotação);
          setTotalPedido(totalPedido);
          setData((state) => [...state, pedidoRow]);
        });

        setFirstPage(response?.dados?.first_page_url);
        setLastPage(response?.dados?.last_page);
        setLastPageUrl(response?.dados?.last_page_url);
        setNextPage(response?.dados?.next_page_url);
        setPrevPage(response?.dados?.prev_page_url);
        setCurrentPage(response?.dados?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.dados?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.dados?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, [handleHistory, history, user]);

  //Pedidos OBJ
  const handleFilter = useCallback(async (statusCotacao, tipoPedido) => {

    // if (statusCotacao === 'outros' || statusCotacao === 'combustiveis') {
    //   console.log(statusCotacao === 'outros' || statusCotacao === 'combustiveis', '111')
    // } else {
    //   console.log('333')
    // }

    const formattedDataDe =  moment().format("YYYY-MM-DD 00:00:00");
    const formattedDataAte =  moment().format("YYYY-MM-DD 23:59:59");

    /* Data Criação */
    const dataDeCreate = DateCreateRange[0] === null ? formattedDataDe : moment(DateCreateRange[0]).format("YYYY-MM-DD 00:00:00");
    const dataAteCreate = DateCreateRange[1] === null ? formattedDataAte : moment(DateCreateRange[1]).format("YYYY-MM-DD 23:59:59");

    /* Data Entrega */
    const dataDeEntrega = DateEntregaRange[0] === null ? null : moment(DateEntregaRange[0]).format("YYYY-MM-DD 00:00:00");
    const dataAteEntrega = DateEntregaRange[1] === null ? null : moment(DateEntregaRange[1]).format("YYYY-MM-DD 23:59:59");

    const checkProduto = filterProduto ? filterProduto.value : ""
    const checkCotacaoOriginal = filterCotacaoOriginal ? filterCotacaoOriginal.value : ""
    const checkProg = filterProg ? filterProg.value : ""
    const checkOperador = filterOperador ? filterOperador.value : ""
    const checkStatus = filterStatus ? filterStatus.value : ""

    var checkStatusCotacao = "";
    var tipoPedido = "";

    checkStatusCotacao = statusCotacao ? statusCotacao : filterStatusCotacao ? filterStatusCotacao.value : "";

    if (statusCotacao === 'outros' || statusCotacao === 'combustivel') {
      checkStatusCotacao = "";
      tipoPedido = statusCotacao;

      setAprovadoFilter(false);
      setAnaliseMesaFilter(false);
      setRenegociacaoFilter(false);
    } else {
      tipoPedido = outrosFilter ? 'outros' : 'combustivel'
    }

    const checkIntegrado = filterIntegrado ? filterIntegrado.value : ""
    const checkNotaServico = filterNotaServiço  ? filterNotaServiço.value : ""
    const checkNotaDistribuidor = filterNotaDitribuidor ? filterNotaDitribuidor.value : ""
    const checkBoleto = filterBoleto ? filterBoleto.value : ""


    const dataFilter = {
      numero_pedido_sistema: filterNumeroPedido,
      numero_pedido_cliente: filterNumeroPedidoCliente,
      data_criacao_de: dataDeCreate,
      data_criacao_ate: dataAteCreate,
      cliente: filterCliente,
      distribuidor: filterDistribuidorTRR,
      operador_id: checkOperador,
      prazo: filterPrazoPagamento,
      data_entrega_desejada_de: dataDeEntrega,
      data_entrega_desejada_ate: dataAteEntrega,
      status_filtro_pedido: checkStatus,
      status_filtro_cotacao: checkStatusCotacao,
      tipo_produto: tipoPedido,
      numero_pedido_sodexo: filterCargaWizeo,
      numero_cotacao_sistema: filterCotacao,
      produto_id: checkProduto,
      nota_cliente_nome: checkNotaServico,
      boleto_cliente_nome: checkBoleto,
      nota_distribuidor_nome: checkNotaDistribuidor,
      status_integracao: checkIntegrado,
      programacao: checkProg,
      cotacao_id_original: checkCotacaoOriginal,
      limit: 40,
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    // salva o histórico de filtros no storage
    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    /** zero a tabela para mostrar somente o retorno */
    setData([]);
    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });

      let totalCotação = 0;
      let totalPedido = 0;

      response.dados.data.forEach((pedido) => {
        totalPedido = totalPedido + 1;
        let pedidoRow = {};
        let cotacoesRows = [];
        let obj = {};
        let cotacaoIdProduto = [];
        let menorPreco = [];

        let produtoFormatted = [];
        produtoFormatted = pedido?.produtos_pedido.map((prod) => {
          return prod.descricao;
        });

        //Integração Status
        let integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
        let integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
        let integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

        let cotacaoMap = [];
        if( integradoSim.length > 0 ){
          cotacaoMap['integrado'] = "Sim"
        }else if(integradoNao.length > 0 && integradoSim.length === 0){
          cotacaoMap['integrado'] = "Não"
        }else if(integradoErro.length > 0){
          cotacaoMap['integrado'] = "Erro"
        }

        let idCotacaoIntegrar = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.status_cotacao === "Aprovado"){
            idCotacaoIntegrar = cotacao.id
          }
        })

        let numeroCargaWizeo = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.numero_pedido_sodexo !== null){
            numeroCargaWizeo = cotacao.numero_pedido_sodexo
          }
        })

        let totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              let prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

        // Cria objeto com os pedidos
        Object.assign(pedidoRow, {
          original: pedido,
          id: pedido.id,
          id_cotacao: idCotacaoIntegrar,
          numeroPedidoSodexo: numeroCargaWizeo,
          numeroPedidoWizeo: numeroCargaWizeo,
          numeroPedido: pedido.numero_pedido_sistema,
          numeroCotacao: "",
          cotacaoOriginal: "",
          totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
          numeroPedidoCliente: pedido.numero_pedido_cliente,
          dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
          dataSemFormatacao: pedido.created_at,
          cliente:
            pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            pedido.cotacoes[0].cliente?.razao_social,
          produto: "",
          distribuidorTRR: "",
          prazoPagamento: pedido?.prazo,
          dataEntrega: format(
            parseISO(pedido.data_entrega_desejada),
            "dd/MM/yyyy"
          ),
          prazoEntrega: "",
          status: pedido?.status_pedido,
          statusCotacao: "",
          valorTotal: "",
          integrado: cotacaoMap['integrado'],
          operador: pedido?.operador?.name,
          criador: pedido?.criado_por?.name,
          responsavel: "",
          historico: (
            <FaHistory
              size={18}
              onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
            />
          ),
          notaServico: (
            <UploadNFServico
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasNF={pedido?.cotacao_faturada[0]?.nota_cliente_nome}
            />
          ),
          notaDistribuidor: (
            <UploadNFDistribuidor
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasNF={pedido?.cotacao_faturada[0]?.nota_distribuidor_nome}
            />
          ),
          boleto: pedido?.cotacao_faturada[0]?.boleto_cliente_nome && (
            <DownloadBoleto
              idCotacao={pedido?.cotacao_faturada[0]?.id}
              hasBoleto={pedido?.cotacao_faturada[0]?.boleto_cliente_nome}
            />
          ),
          programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
          cotacaoOriginalExcel: "",
          produtoExcel: produtoFormatted.toString(),
          valorTotalExcel: "",
          distribuidor_id: "",
          distribuidor_preferencia_id: "",
        });

        // Cria objeto com as Cotações
        pedido.cotacoes.forEach((cotacao) => {
          totalCotação = totalCotação + 1;
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              ?.replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          cotacao.produtos_cotacao.map((prod) => {
            obj = {};

            console.log(prod, '99999999999999999')
            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedidoSodexo: "",
              numeroPedidoWizeo: "",
              numeroPedido: "",
              numeroCotacao: cotacao.numero_cotacao_sistema,
              cotacaoOriginal: <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
              dataSemFormatacao: pedido.created_at,
              cliente: "",
              produto: prod?.produto?.descricao,
              cotacao_produto_id: prod?.id,
              valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
              preco: ((parseFloat(prod?.valor_unitario) +  parseFloat(prod?.valor_frete)).toFixed(4)).toString(),
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              prazoPagamento: "",
              dataEntrega: "",
              prazoEntrega: prod?.prazo_entrega,
              status: pedido?.status_pedido,
              statusCotacao: cotacao?.status_cotacao,
              integrado:"",
              operador: "",
              criador: "",
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: "",
              notaServico: "",
              notaDistribuidor: "",
              boleto: "",
              programados: "",
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
              cotacaoIdProduto: cotacaoIdProduto
            });

            cotacoesRows.push(obj);
            return prod;
          });
        });

        // Ordena Cotação por produto e preço
        cotacoesRows = cotacoesRows.sort((a, b) => {
          function value(el) {
            let x = el;
            return x === "0.0000" ? Infinity : x;
          }

          return (
            a.produto.localeCompare(b.produto) ||
            value(a.preco) - value(b.preco)
          );
        });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }
            return i;
          });


        Object.assign(pedidoRow, {
          subRows: cotacoesRows,
        });

        setTotalCotacao(totalCotação);
        setTotalPedido(totalPedido);
        setData((state) => [...state, pedidoRow]);
      });

      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber =  5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });
      setLoadingListFilter(false);
      setFilterButton(false);

      if (dataDeCreate > dataAteCreate){
        toast.error("Data de criação inicial maior do que a data final");
      }

      if (dataDeEntrega > dataAteEntrega){
        toast.error("Data de entrega inicial maior do que a data final");
      }

    } catch (error) {
      console.log(error);
      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [
    user,
    filterNumeroPedido,
    filterNumeroPedidoCliente,
    filterCliente,
    filterDistribuidorTRR,
    filterOperador,
    filterPrazoPagamento,
    filterStatus,
    filterStatusCotacao,
    filterCargaWizeo,
    filterCotacao,
    filterProduto,
    // filterCriador,
    // filterResponsavel,
    filterNotaServiço,
    filterBoleto,
    filterNotaDitribuidor,
    filterIntegrado,
    filterProg,
    filterCotacaoOriginal,
    handleHistory,

    DateCreateRange,
    DateEntregaRange,
    outrosFilter
  ]);

  //Pedidos OBJ
  const handleRemoveFilter = useCallback(async () => {
    const today = new Date();
    const formattedDate = format(today, "dd/MM/yyyy");

    const dataFilter = {
      numero_pedido_sistema: "",
      numero_pedido_cliente: "",
      created_at: formattedDate,
      cliente: "",
      distribuidor: "",
      operador_id: "",
      prazo: "",
      data_entrega_desejada: "",
      status_filtro_pedido: "",
      status_filtro_cotacao: "",
      numero_pedido_sodexo: "",
      numero_cotacao_sistema: "",
      produto_id: "",
      criado_por_user_id: "",
      ultimo_editor_preco: "",
      nota_cliente_nome: "",
      boleto_cliente_nome: "",
      nota_distribuidor_nome: "",
      status_integracao: "",
      programacao: "",
      cotacao_id_original: "",
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    setFilterNumeroPedido("")
    setFilterCotacao("")
    setDateRange([null, null])
    setFilterCliente("")
    setFilterProduto({value: "", label :""})
    setFilterDistribuidorTRR("")
    setFilterCotacaoOriginal({value: "", label :""})
    setFilterNumeroPedidoCliente("")
    setFilterCargaWizeo("")
    setFilterProg({value: "", label :""})
    setFilterOperador({value: "", label :""})
    // setFilterCriador({value: "", label :""})
    // setFilterResponsavel({value: "", label :""})
    setFilterPrazoPagamento("")
    setEntregaRange([null, null])
    setFilterStatus({value: "", label :""})
    setFilterStatusCotacao({value: "", label :""})
    setFilterIntegrado({value: "", label :""})
    setFilterNotaServiço({value: "", label :""})
    setFilterNotaDistribuidor({value: "", label :""})
    setFilterBoleto({value: "", label :""})

    /** zero a tabela para mostrar somente o retorno */
    setData([]);
    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });


      let totalCotação = 0;
      let totalPedido = 0;

      response.dados.data.forEach((pedido) => {
        totalPedido = totalPedido + 1;
        let pedidoRow = {};
        let cotacoesRows = [];
        let obj = {};
        let cotacaoIdProduto = [];
        let menorPreco = [];

        let produtoFormatted = [];
        produtoFormatted = pedido?.produtos_pedido.map((prod) => {
          return prod.descricao;
        });

        //Integração Status
        let integradoSim = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 1) ) )
        let integradoNao = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao === 0) ) )
        let integradoErro = ( pedido.cotacoes.filter( cotacao => (cotacao.status_integracao >= 2) ) )

        let cotacaoMap = [];
        if( integradoSim.length > 0 ){
          cotacaoMap['integrado'] = "Sim"
        }else if(integradoNao.length > 0 && integradoSim.length === 0){
          cotacaoMap['integrado'] = "Não"
        }else if(integradoErro.length > 0){
          cotacaoMap['integrado'] = "Erro"
        }

        let idCotacaoIntegrar = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.status_cotacao === "Aprovado"){
            idCotacaoIntegrar = cotacao.id
          }
        })

        let numeroCargaWizeo = "";

        pedido.cotacoes.forEach((cotacao) => {
          if (cotacao.numero_pedido_sodexo !== null){
            numeroCargaWizeo = cotacao.numero_pedido_sodexo
          }
        })

        let totalPedidoAprovado = ""

          pedido.cotacoes.forEach((cotacao) => {
            const taxAdm = cotacao?.taxa_administrativa_unitaria;
            const taxFinanceira = pedido?.cadastro?.taxa_financeira

            if (cotacao.status_cotacao === "Aprovado" || cotacao.status_cotacao === "Faturado" || cotacao.status_cotacao === "Aguardando Faturamento") {
              let prazoAprovado = cotacao.prazos.filter((item) => {
                return item.prazo === pedido.prazo;
              });

              let precoLitroFinal = 0;
              let totalLinhaSemTaxas = 0;
              let totalLinhaProduto = 0;
              let totalProdutoSemTaxa = 0;
              let totalProdutoComTaxa = 0;
              let idPrazo = prazoAprovado[0].id;
              let prazoCotacao = prazoAprovado[0].prazo;
              let totalLinhaFrete = 0;
              let totalLinhaTaxaFinanceira = 0;
              let taxaFinan = "";


              const arr = prazoAprovado[0].produtos.map((prod) => {
                const obj = {};
                let filterTaxa = [];

                const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                  (cot_prod) => {
                    return cot_prod.produto_id === prod.produto_id;
                  }
                );

                if (!prod.taxa_financeira_percentual) {
                  filterTaxa = taxFinanceira.filter((tax) => {
                    if (
                      parseInt(prazoAprovado[0].prazo) >=
                        parseInt(tax.taxa_financeira_de) &&
                      parseInt(prazoAprovado[0].prazo) <= parseInt(tax.taxa_financeira_ate)
                    ) {
                      return true;
                    }

                    return false;
                  });

                  taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
                } else {
                  taxaFinan = prod.taxa_financeira_percentual;
                }

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(
                      parseFloat(prod?.valor_unitario) +
                        parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                    ) *
                      (parseFloat(taxaFinan) / 100 + 1) +
                    parseFloat(taxAdm)
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Preço Litro Final
                  precoLitroFinal = (
                    parseFloat(prod?.valor_unitario) +
                    parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                }

                // Valor Total sem taxas produto linha
                totalLinhaSemTaxas = (
                  parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });

                if (parseInt(user.cadastro_tipo_id) !== 2) {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    parseFloat(
                      (
                        (parseFloat(prod?.valor_unitario) +
                          parseFloat(prod?.valor_frete || 0)) *
                        (parseFloat(taxaFinan) / 100 + 1)
                      )
                    ) *
                      parseFloat(prod?.quantidade) +
                    parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                  ).toFixed(2); //arredondamento;

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 8,
                  });
                } else {
                  // Valor Total produto linha
                  totalLinhaProduto = (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0)) *
                    parseFloat(prod?.quantidade)
                  ).toFixed(2);

                  totalLinhaProduto = parseFloat(
                    totalLinhaProduto
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  });
                }

                //Soma total Sem taxas Com Frete
                totalProdutoSemTaxa =
                  totalProdutoSemTaxa +
                  parseFloat(prod?.quantidade) *
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete || 0));

                //Soma total Com taxas + Frete
                // Calcula o total do pedido inteiro com todas as taxas.

                // Valor total da cotação com todas as taxas
                totalProdutoComTaxa =
                  parseFloat(totalProdutoComTaxa) +
                  parseFloat(
                    totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                  );

                totalLinhaFrete =
                  parseFloat(prod?.valor_frete || 0) *
                  parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira =
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100)
                    )
                  ) * parseFloat(prod?.quantidade);

                totalLinhaTaxaFinanceira = parseFloat(
                  totalLinhaTaxaFinanceira
                );

                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  descricao: prod.produto.descricao,
                  prazo: prazoAprovado[0].prazo,
                  quantidade: prod.quantidade || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                });

                return obj;
              });

              arr.map((prod) => {
                prod.total_cotacao_sem_taxa =
                  parseFloat(totalProdutoSemTaxa.toFixed(4)) || 0;
                prod.total_cotacao_com_taxa =
                  parseFloat(totalProdutoComTaxa.toFixed(4)) || 0;
                return prod;
              });

              totalPedidoAprovado = arr[0].total_cotacao_com_taxa;

              return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
            }
          })

        // Cria objeto com os pedidos
        Object.assign(pedidoRow, {
          original: pedido,
          id: pedido.id,
          id_cotacao: idCotacaoIntegrar,
          numeroPedidoSodexo: numeroCargaWizeo,
          numeroPedidoWizeo: numeroCargaWizeo,
          numeroPedido: pedido.numero_pedido_sistema,
          numeroCotacao: "",
          cotacaoOriginal: "",
          totalPedidoAprovado: totalPedidoAprovado.toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
          numeroPedidoCliente: pedido.numero_pedido_cliente,
          dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
          dataSemFormatacao: pedido.created_at,
          cliente:
            pedido.cotacoes[0].cliente?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            pedido.cotacoes[0].cliente?.razao_social,
          produto: "",
          distribuidorTRR: "",
          prazoPagamento: pedido?.prazo,
          dataEntrega: format(
            parseISO(pedido.data_entrega_desejada),
            "dd/MM/yyyy"
          ),
          prazoEntrega: "",
          status: pedido?.status_pedido,
          statusCotacao: "",
          valorTotal: "",
          integrado: cotacaoMap['integrado'],
          operador: pedido?.operador?.name,
          criador: pedido?.criado_por?.name,
          responsavel: "",
          historico: (
            <FaHistory
              size={18}
              onClick={() => handleHistory(pedido.id, cotacaoIdProduto)}
            />
          ),
          notaServico: "",
          notaDistribuidor: "",
          boleto: "",
          programados: pedido?.programacao.length > 0 ? "Sim" : "Não",
          cotacaoOriginalExcel: "",
          produtoExcel: produtoFormatted.toString(),
          valorTotalExcel: "",
          distribuidor_id: "",
          distribuidor_preferencia_id: "",
        });

        // Cria objeto com as Cotações
        pedido.cotacoes.forEach((cotacao) => {
          totalCotação = totalCotação + 1;
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              ?.replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          cotacao.produtos_cotacao.map((prod) => {
            obj = {};

            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedidoSodexo: "",
              numeroPedidoWizeo: "",
              numeroPedido: "",
              numeroCotacao: cotacao.numero_cotacao_sistema,
              cotacaoOriginal: <PedidoLink item={cotacao?.cotacao_original} menorPreco={cotacaoIdProduto} />,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy - HH:mm"),
              dataSemFormatacao: pedido.created_at,
              cliente: "",
              produto: prod?.produto?.descricao,
              cotacao_produto_id: prod?.id,
              valorTotal: (parseFloat(prod?.quantidade) / 1000).toFixed(2),
              preco: ((parseFloat(prod?.valor_unitario) +  parseFloat(prod?.valor_frete)).toFixed(4)).toString(),
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              prazoPagamento: "",
              dataEntrega: "",
              prazoEntrega: prod?.prazo_entrega,
              status: pedido?.status_pedido,
              statusCotacao: cotacao?.status_cotacao,
              integrado: "",
              operador: "",
              criador: "",
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: "",
              notaServico: (
                <UploadNFServico
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_cliente_nome}
                />
              ),
              notaDistribuidor: (
                <UploadNFDistribuidor
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_distribuidor_nome}
                />
              ),
              boleto: cotacao.boleto_cliente_nome && (
                <DownloadBoleto
                  idCotacao={cotacao.id}
                  hasBoleto={cotacao.boleto_cliente_nome}
                />
              ),
              programados: "",
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
              cotacaoIdProduto: cotacaoIdProduto
            });


            cotacoesRows.push(obj);
            return prod;
          });
        });

        // Ordena Cotação por produto e preço
        cotacoesRows = cotacoesRows.sort((a, b) => {
          function value(el) {
            let x = el;
            return x === "0.0000" ? Infinity : x;
          }

          return (
            a.produto.localeCompare(b.produto) ||
            value(a.preco) - value(b.preco)
          );
        });

          // Sempre adiciona a primeira posição do array quando valor diferente de 0
          if (cotacoesRows[0].preco !== "0.0000") {
            cotacoesRows[0].blink = true;
            cotacaoIdProduto.push(cotacoesRows[0].cotacao_produto_id)
            menorPreco.push([cotacoesRows[0].preco, cotacoesRows[0].produto])
          }

          // Faz uma busca pelo restante do array e adiciona os menores preços caso os produtos sejam diferentes e diferente de 0
          cotacoesRows = cotacoesRows.map((i, index) => {
            if (i.produto !== cotacoesRows[index - 1 < 0 ? 0 : index - 1]?.produto && cotacoesRows[index].preco !== "0.0000") {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
              menorPreco.push([i?.preco, i?.produto])
            }

            // "menorPrecoIgual" existe para adicionar o menor preço que possui o mesmo valor em outras cotações
            if (menorPreco.find( menorPrecoIgual =>  menorPrecoIgual[0] === i?.preco && menorPrecoIgual[1] === i?.produto)) {
              i.blink = true;
              cotacaoIdProduto.push(i.cotacao_produto_id)
            }
            return i;
          });


        Object.assign(pedidoRow, {
          subRows: cotacoesRows,
        });

        setTotalCotacao(totalCotação);
        setTotalPedido(totalPedido);
        setData((state) => [...state, pedidoRow]);
      });

      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setLoadingListFilter(false);
      setFilterButton(false);
    } catch (error) {
      console.log(error);
      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [handleHistory, user]);

  const handleCreate = useCallback(() => {
    history.push("/orders/new/v2");
  }, [history]);

  const handleDisplay = useCallback(
    async (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaServico;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;
      delete data.original?.cotacaoOriginal;

      history.push("/orders/painel/show", data.original);
    },
    [history]
  );

  const handleEdit = useCallback(
    (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaServico;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;
      delete data.original?.cotacaoOriginal;

      history.push("/orders/painel/edit", data.original);
    },
    [history]
  );

  const handleCancelOrders = useCallback(() => {
    history.push("/orders/painel/cancel", { pedidosId: selectedOrders });
  }, [history, selectedOrders]);

  const handleCancelOrdersLine = useCallback(
    (idPedido) => {
      history.push("/orders/painel/cancel", { pedidosId: [idPedido] });
    },
    [history]
  );

  const handleSimulation = useCallback(
    () => {
      history.push("/orders/painel/simulation", { tipo: outrosFilter })
    },
    [history, outrosFilter]
  );


  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);
  const handleClickCheckbox = useCallback(
    (e) => {
      // Seleciona Todas os Checkbox
      e.checked = true;
      setCheck(!check);
      const inputs = document.querySelectorAll("input[type=checkbox]");

      inputs.forEach((input) => {
        if (check) {
          input.checked = check;

          let id = [];

          data.map((item) => {
            return id.push(`${item.id_cotacao}-${item.status}-${item.id}`);
          });

          id = id.filter((elem, i) => id.indexOf(elem) === i);

          setSelectedOrders(id);
        } else {
          input.checked = check;

          setSelectedOrders([]);
        }
      });
    },
    [check, data]
  );

  const handleSelectOrders = useCallback((row, e) => {
    // Seleciona um checkbox

    if (e.checked === true) {
      setSelectedOrders((state) => [
        ...state,
        `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`,
      ]);
    } else {
      setSelectedOrders((state) =>
        state.filter(
          (order) =>
            order !==
            `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
        )
      );
    }
  }, []);

  const handleReenviarEmail = useCallback(async (cotacao) => {
    setLoadingEmail(true);
    try {
      await api.get(`/email/pedido-aprovado/${cotacao}`);
      setLoadingEmail(false);
      toast.success("E-mails de confirmação de pedido reenviados com sucesso.");

    } catch (error) {
      toast.error("Erro no envio do e-mails da confirmação do pedido.");
      setLoadingEmail(false);
    }
  }, []);

  const handleDownloadCliente = useCallback(async (id) => {
    setLoadingEspelhoCliente(true);

      try {
        const download = await api.get(
          `/cotacao/${id}/download-espelho-cliente`,
          {
            responseType: "blob",
          }
        );

        const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", "Espelho Cliente.pdf"); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();

        setLoadingEspelhoCliente(false);

      } catch (error) {
        setLoadingEspelhoCliente(false);
        console.log(error);
      }
  }, []);

  const handleDownloadDistribuidor = useCallback(async (id) => {
    setLoadingEspelhoDistribuidor(true);

    try {
      const download = await api.get(
        `/cotacao/${id}/download-espelho-distribuidor`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Espelho Distribuidor/TRR.pdf"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
      setLoadingEspelhoDistribuidor(false);

    } catch (error) {
      setLoadingEspelhoDistribuidor(false);
      console.log(error);
    }
}, []);

  const handleCancelCotacaoLine = useCallback(
    (idCotacao) => {
      history.push("/orders/painel/cancel", { cotacaoId: idCotacao });
    },
    [history]
  );

  const handleIntegrateOrder = useCallback(
    async (data, tipo) => {
      if (tipo === "reintegrar") {
        const reintegrarAlert = await swal({
          title: voceTemCerteza,
          text: "Deseja reintegrar o pedido?",
          icon: "warning",
          buttons: {
            cancel: {
              text: cancelar,
              value: null,
              visible: true,
              closeModal: true,
              className: "teste",
            },
            confirm: {
              text: opcaoSim,
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          dangerMode: true,
        });

        if (reintegrarAlert) {
          try {
            setIntegrateButton(true);

            await api.post(`/cotacao/integrar`, { ids: [data?.id_cotacao] });

            toast.success("Pedido reintegrado com sucesso!");
            setIntegrateButton(false);
            history.go(0);
          } catch (err) {
            toast.error("Erro ao reintegrar o pedido");
            setIntegrateButton(false);
          }
        }
      } else {
        try {
          setIntegrateButton(true);

          await api.post(`/cotacao/integrar`, { ids: [data?.id_cotacao] });

          toast.success("Pedido integrado com sucesso!");
          setIntegrateButton(false);
          history.go(0);
        } catch (err) {
          toast.error("Erro ao integrar o pedido");
          setIntegrateButton(false);
        }
      }
    },
    [voceTemCerteza, cancelar, opcaoSim, history]
  );

  const handleIntegrateMultiOrder = useCallback(async () => {
    setIntegrateMultiButtonLoading(true);
    const formattedOrders = selectedOrders.map((elem) => {
      return { id: elem.split("-")[0], status: elem.split("-")[1] };
    });

    let checkOrders = formattedOrders.filter(
      (elem) =>
        elem.status === "Aprovado" ||
        elem.status === "Aguardando Faturamento" ||
        elem.status === "Faturado"
    );

    if (checkOrders.length === 0) {
      setIntegrateMultiButtonLoading(false);
      return toast.warning("Nenhum pedido selecionado com status aprovado.");
    }

    checkOrders = checkOrders.map((item) => item.id && parseInt(item.id));

    try {
      await api.post(`/cotacao/integrar`, { ids: checkOrders });

      toast.success("Pedido integrado com sucesso!");
      history.go(0);
      setIntegrateMultiButtonLoading(false);
    } catch (err) {
      toast.error("Erro ao integrar o pedido");
      setIntegrateMultiButtonLoading(false);
    }
  }, [history, selectedOrders]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnOrder,
      expanded,
      columnVisibility,
    },
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    getSubRows: row => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const handleChangePrazo = useCallback(() => {
    setExibirPrazo(!exibirPrazo)

    let obj = {};
    if (!exibirPrazo) {
      obj.prazoPagamento = true; obj.dataEntrega = true; obj.reemsolso = true; obj.prazoEntrega = true
    } else {
      obj.prazoPagamento = false; obj.dataEntrega = false; obj.reemsolso = false; obj.prazoEntrega = false
    }

    if (exibirFiscais) {
      obj.notaServico = true; obj.notaDistribuidor = true; obj.boleto = true; obj.historico = true;
    } else {
      obj.notaServico = false; obj.notaDistribuidor = false; obj.boleto = false; obj.historico = false;
    }

    if (exibirInfos) {
      obj.numeroPedido = true; obj.cotacaoOriginal = true; obj.numeroPedidoWizeo = true; obj.programados = true; obj.operador = true; obj.criador = true;obj.responsavel = true; obj.numeroCotacao = true;
    } else {
      obj.numeroPedido = false; obj.cotacaoOriginal = false; obj.numeroPedidoWizeo = false; obj.programados = false; obj.operador = false; obj.criador = false; obj.responsavel = false; obj.numeroCotacao = false;
    }

    if (outrosFilter) {
      obj.marca = true; obj.categoria = true; obj.tipoUnitario = true; obj.quantidade = true;
      obj.valorTotal = false; obj.preco = true;
    } else {
      obj.marca = false; obj.categoria = false; obj.tipoUnitario = false; obj.quantidade = false;
      obj.valorTotal = true;obj. preco = true;
    }

    setColumnVisibility(obj)
  },[exibirPrazo, columnVisibility]);

  const handleChangeFiscais = useCallback(() => {
    setExibirFiscais(!exibirFiscais)

    let obj = {};
    if (exibirPrazo) {
      obj.prazoPagamento = true; obj.dataEntrega = true; obj.reemsolso = true; obj.prazoEntrega = true
    } else {
      obj.prazoPagamento = false; obj.dataEntrega = false; obj.reemsolso = false; obj.prazoEntrega = false
    }

    if (!exibirFiscais) {
      obj.notaServico = true; obj.notaDistribuidor = true; obj.boleto = true; obj.historico = true;
    } else {
      obj.notaServico = false; obj.notaDistribuidor = false; obj.boleto = false; obj.historico = false;
    }

    if (exibirInfos) {
      obj.numeroPedido = true; obj.cotacaoOriginal = true; obj.numeroPedidoWizeo = true; obj.programados = true; obj.operador = true; obj.criador = true;obj.responsavel = true; obj.numeroCotacao = true;
    } else {
      obj.numeroPedido = false; obj.cotacaoOriginal = false; obj.numeroPedidoWizeo = false; obj.programados = false; obj.operador = false; obj.criador = false; obj.responsavel = false; obj.numeroCotacao = false;
    }

    if (outrosFilter) {
      obj.marca = true; obj.categoria = true; obj.tipoUnitario = true; obj.quantidade = true;
      obj.valorTotal = false; obj.preco = true;
    } else {
      obj.marca = false; obj.categoria = false; obj.tipoUnitario = false; obj.quantidade = false;
      obj.valorTotal = true;obj. preco = true;
    }

    setColumnVisibility(obj)
  },[exibirFiscais, columnVisibility]);

const handleChangeInfos = useCallback(() => {
  setExibirInfos(!exibirInfos)

  let obj = {};
  if (exibirPrazo) {
    obj.notaServico = true; obj.notaDistribuidor = true; obj.boleto = true; obj.historico = true;
  } else {
    obj.notaServico = false; obj.notaDistribuidor = false; obj.boleto = false; obj.historico = false;
  }

  if (exibirFiscais) {
    obj.prazoPagamento = true; obj.dataEntrega = true; obj.reemsolso = true; obj.prazoEntrega = true
  } else {
    obj.prazoPagamento = false; obj.dataEntrega = false; obj.reemsolso = false; obj.prazoEntrega = false
  }

  if (!exibirInfos) {
    obj.numeroPedido = true; obj.cotacaoOriginal = true; obj.numeroPedidoWizeo = true; obj.programados = true; obj.operador = true; obj.criador = true;obj.responsavel = true; obj.numeroCotacao = true;
  } else {
    obj.numeroPedido = false; obj.cotacaoOriginal = false; obj.numeroPedidoWizeo = false; obj.programados = false; obj.operador = false; obj.criador = false; obj.responsavel = false; obj.numeroCotacao = false;
  }

  if (outrosFilter) {
    obj.marca = true; obj.categoria = true; obj.tipoUnitario = true; obj.quantidade = true;
    obj.valorTotal = false; obj.preco = true;
  } else {
    obj.marca = false; obj.categoria = false; obj.tipoUnitario = false; obj.quantidade = false;
    obj.valorTotal = true;obj. preco = true;
  }

  setColumnVisibility(obj)
},[exibirInfos, columnVisibility]);

const handleChangeProdutos = useCallback(() => {
  setOutrosFilter(!outrosFilter)

  let obj = {};
  if (!outrosFilter) {
    obj.marca = true; obj.categoria = true; obj.tipoUnitario = true; obj.quantidade = true;
    obj.valorTotal = false; obj.preco = true;
  } else {
    obj.marca = false; obj.categoria = false; obj.tipoUnitario = false; obj.quantidade = false;
    obj.valorTotal = true;obj. preco = true;
  }

  if (exibirPrazo) {
    obj.notaServico = true; obj.notaDistribuidor = true; obj.boleto = true; obj.historico = true;
  } else {
    obj.notaServico = false; obj.notaDistribuidor = false; obj.boleto = false; obj.historico = false;
  }

  if (exibirFiscais) {
    obj.prazoPagamento = true; obj.dataEntrega = true; obj.reemsolso = true; obj.prazoEntrega = true
  } else {
    obj.prazoPagamento = false; obj.dataEntrega = false; obj.reemsolso = false; obj.prazoEntrega = false
  }

  if (exibirInfos) {
    obj.numeroPedido = true; obj.cotacaoOriginal = true; obj.numeroPedidoWizeo = true; obj.programados = true; obj.operador = true; obj.criador = true;obj.responsavel = true; obj.numeroCotacao = true;
  } else {
    obj.numeroPedido = false; obj.cotacaoOriginal = false; obj.numeroPedidoWizeo = false; obj.programados = false; obj.operador = false; obj.criador = false; obj.responsavel = false; obj.numeroCotacao = false;
  }

  setColumnVisibility(obj)
},[exibirPrazo, columnVisibility]);

  const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
    columnOrder.splice(
      columnOrder.indexOf(targetColumnId),
      0,
      columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
    )
    return [...columnOrder]
  }

  const DraggableColumnHeader = ({ header, table }) => {
    const { getState, setColumnOrder } = table
    const { columnOrder } = getState()
    const { column } = header

    const [, dropRef] = useDrop({
      accept: 'column',
      drop: (draggedColumn) => {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrder
        )
        localStorage.setItem("columnOrder",JSON.stringify(newColumnOrder));
        setColumnOrder(newColumnOrder)
      },
    })

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => column,
      type: 'column',
    })
    return (
      <th key={header.id} colSpan={header.colSpan} ref={dropRef} style={{ opacity: isDragging ? 0.5 : 1, width:  header.column.columnDef.size}} >
        {header.isPlaceholder ? null : (
            <div ref={previewRef}>
              {column.id !== 'expander' && (
                <button ref={dragRef}> <FaList /></button>
              )}
              {flexRender(
                header.column.columnDef.header,
                header.getContext()
              )}
            </div>
          )}
        </th>
    )
  }

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonIcon
              Icon={FiArrowLeft}
              backgroundColor={"#c8c8c8"}
              color={"#444"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="painelControle" />
            </h2>
          </nav>

          <div>
            <>
              <ButtonIcon
                Icon={FiFilter}
                backgroundColor={"#d4bb31"}
                placeholder={<FormattedMessage id="filtrar" />}
                onClick={() => setShowFilter(!showFilter)}
                loading={filterButton}
              />

              <ButtonIcon
                Icon={FiTrash2}
                backgroundColor={"#d43131"}
                placeholder={"Limpar Filtro"}
                onClick={() => handleRemoveFilter()}
                loading={filterButton}
              />

              <ButtonIcon
                Icon={FiAlertCircle}
                backgroundColor={"#de872cf0"}
                placeholder={"Legendas"}
                onClick={() => setShowLegends(!showLegends)}
              />

              <ButtonIcon
                Icon={FiPlus}
                backgroundColor={"#931a28"}
                onClick={handleCreate}
                permission="pedido-store[1]"
                placeholder={<FormattedMessage id="novoPedido" />}
              />

              <ButtonIcon
                Icon={FaEllipsisH}
                backgroundColor={"#4c84ec"}
                placeholder={<FormattedMessage id="acoes" />}
                onClick={() => setShowAction(!showAction)}
              />
            </>

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        <ContainerButton>
          <button
           style={
            aprovadoFilter
              ? { backgroundColor: "#355ca5"}
              : { backgroundColor: "#8d8d8d"}
            }
            onClick={() => {
              setAprovadoFilter(!aprovadoFilter);
              setAnaliseMesaFilter(false);
              setRenegociacaoFilter(false);
              handleFilter(!aprovadoFilter ? "Aprovado" : "");
            }}
          >
            <FormattedMessage id="aprovado" />
          </button>
          <button
           style={
            analiseMesaFilter
              ? { backgroundColor: "#355ca5"}
              : { backgroundColor: "#8d8d8d"}
            }
            onClick={() => {
              setAnaliseMesaFilter(!analiseMesaFilter)
              setAprovadoFilter(false);
              setRenegociacaoFilter(false);
              handleFilter(!analiseMesaFilter ? "Análise Mesa de Operação" : "");
            }}
          >
            <FormattedMessage id="analiseMesa" />
          </button>
          <button
           style={
            renegociacaoFilter
              ? { backgroundColor: "#355ca5"}
              : { backgroundColor: "#8d8d8d"}
            }
            onClick={() => {
              setRenegociacaoFilter(!renegociacaoFilter)
              setAnaliseMesaFilter(false)
              setAprovadoFilter(false);
              handleFilter(!renegociacaoFilter ? "Reanálise Distribuidor/TRR" : "");
            }}
          >
            <FormattedMessage id="renegociacao" />
          </button>
          <button
            style={{backgroundColor: "#8d8d8d"}}
            onClick={() => handleSimulation()}
          >
            <FormattedMessage id="simulacao" />
          </button>
          <button
            style={outrosFilter ? {backgroundColor: "#8d8d8d"} : {backgroundColor: "#8d8d8d"}}
            onClick={() => {
              handleChangeProdutos();
              handleFilter(!outrosFilter ? 'outros' : 'combustivel')
            }}
          >
            {outrosFilter ? <FormattedMessage id="combustiveis" /> : <FormattedMessage id="outrosProdutos" />}
          </button>


          <button
           style={
            exibirInfos
              ? { backgroundColor: "#1a7343", float: 'right'}
              : { backgroundColor: "#8d8d8d",  float: 'right'}
            }
            onClick={(e) => {
              handleChangeInfos();
            }}
          >
            <FormattedMessage id="outrasInfos" />
          </button>
          <button
            style={
              exibirPrazo
                ? { backgroundColor: "#1a7343", float: 'right'}
                : { backgroundColor: "#8d8d8d",  float: 'right'}
            }
            onClick={(e) => {
              handleChangePrazo();
            }}
          >
            <FormattedMessage id="prazos" />
          </button>
          <button
           style={
            exibirFiscais
              ? { backgroundColor: "#1a7343", float: 'right'}
              : { backgroundColor: "#8d8d8d",  float: 'right'}
            }
            onClick={(e) => {
              handleChangeFiscais();
            }}
          >
            <FormattedMessage id="fiscais" />
          </button>

        </ContainerButton>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <table>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                     <th style={{width: 100}}>
                         <FormattedMessage id="selecionar" />
                       </th>
                       <th style={{width: 100}}>
                          <FormattedMessage id="acoes" />
                      </th>
                    {headerGroup.headers.map(header => (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}

                      />
                    ))}
                    <th style={{width: 100}}>
                        <FormattedMessage id="acoes" />
                    </th>
                  </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(row => {
                    return (
                      <tr key={row.id}>
                        <td>
                          {/* Valida se é uma cotação e nao o pedido */}
                          {row.id.indexOf(".") !== -1 && (
                            <input
                              type="checkbox"
                              onClick={(e) =>
                                handleSelectOrders(row, e.target)
                              }
                            />
                          )}
                        </td>

                        {
                          // Acões
                          /* Valida se é uma cotação e nao o pedido */
                          row.id.indexOf(".") === -1 ? (
                            <td>
                              <OptionsList style={{position: 'inherit'}}>
                                <li>
                                  <OptionsIconPlaceholder
                                    Icon={ImCancelCircle}
                                    placeholder={"Cancelar Pedido"}
                                    onClick={() =>
                                      handleCancelOrdersLine(
                                        `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                      )
                                    }
                                    permission="pedido-cancelar[1]"
                                  />
                                </li>

                                  {row.original.status === "Aprovado" ||
                                      row.original.status === "Aguardando Faturamento" ||
                                      row.original.status === "Faturado" ? (
                                        <>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={AiOutlineMail}
                                              placeholder={"Reenviar E-mails"}
                                              onClick={() =>
                                                handleReenviarEmail(
                                                  `${row.original.id_cotacao}`
                                                )
                                              }
                                              loading={loadingEmail}
                                            />
                                          </li>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={iconEspelhoCliente}
                                              image={true}
                                              placeholder={"Espelho Cliente"}
                                              onClick={() =>
                                                handleDownloadCliente(
                                                  `${row.original.id}`
                                                )
                                              }
                                              loading={loadingEspelhoCliente}
                                            />
                                          </li>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={iconEspelhoDistribuidor}
                                              image={true}
                                              placeholder={"Espelho Distribuidor/TRR"}
                                              onClick={() =>
                                                handleDownloadDistribuidor(
                                                  `${row.original.id}`
                                                )
                                              }
                                              loading={loadingEspelhoDistribuidor}
                                            />
                                          </li>
                                        </>
                                      ) : (
                                        ""
                                      ) }

                                {/* integrado pedido */}
                                {(row.original.status ===
                                    "Aprovado" || row.original.status ===
                                    "Aguardando Faturamento"  || row.original.status ===
                                    "Faturado") && !outrosFilter ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )}

                              </OptionsList>
                            </td>
                          ) : (
                            <td>
                              <OptionsList>
                                <li>
                                  <OptionsIconPlaceholder
                                    Icon={FiEye}
                                    placeholder={
                                      <FormattedMessage id="exibir" />
                                    }
                                    onClick={() => {
                                      return handleDisplay(row);
                                    }}
                                    permission="pedido-show[1]"
                                  />
                                </li>

                                {row.original.status === "Aprovado" ||
                                row.original.status === "Faturado" ||
                                row.original.status ===
                                  "Aguardando Faturamento" ||
                                row.original.status === "Reprovado" ||
                                row.original.status === "Cancelado" ||
                                row.original.status ===
                                  "Aguardando Aprovação" ||
                                row.original.statusCotacao ===
                                  "Cancelado" ? (
                                  <></>
                                ) : (
                                  <li>
                                    <OptionsIconPlaceholder
                                      Icon={FiEdit}
                                      placeholder={
                                        <FormattedMessage id="editar" />
                                      }
                                      onClick={() => handleEdit(row)}
                                      permission="pedido-update[1]"
                                    />
                                  </li>
                                )}

                                {row.original.status !== "Cotação" ? (
                                  <></>
                                ) : (
                                  <li>
                                    <OptionsIconPlaceholder
                                      Icon={FiTrash2}
                                      placeholder={"Cancelar Cotação"}
                                      onClick={() =>
                                        handleCancelCotacaoLine(
                                          row.original.id_cotacao
                                        )
                                      }
                                      permission="pedido-cancelar[1]"
                                    />
                                  </li>
                                )}
                              </OptionsList>
                            </td>
                          )
                        }

                        {row.getVisibleCells().map(cell => {

                          let style;
                          let colorCell;

                          let horaAgora = format(new Date(), "HH:mm")
                          let dataAgora = format(new Date(), "dd/MM/yyyy")

                          let limiteHoraPedido = moment(
                            row.original.dataSemFormatacao
                          ).add(
                              'hours', 2
                          );

                          let limiteDataPedido = row.original.dataPedido.split(" ");

                          if (cell.column.id === "dataPedido") {
                            if (horaAgora > limiteHoraPedido.format("HH:mm") && (cell.row.original.statusCotacao === "Análise Distribuidor/TRR" || (cell.row.original.status === "Cotação" && cell.row.original.statusCotacao === ""))) {
                              colorCell = "#f33e3ec7"
                            }

                            if (dataAgora > limiteDataPedido[0] && (cell.row.original.statusCotacao === "Análise Distribuidor/TRR" || (cell.row.original.status === "Cotação" && cell.row.original.statusCotacao === ""))) {
                              colorCell = "#f33e3ec7"
                            }

                            style = {
                              background: colorCell,
                              fontWeight: "bold",
                            };
                          }

                          if (
                            cell.column.id === "status" &&
                            cell.row.id.indexOf(".") !== -1
                          ) {
                            style = {
                              color: "transparent"
                            };
                          }

                          if (cell.column.id === "integrado") {
                            if (cell.row.original.integrado === "Não") {
                              colorCell = "#edea4c";
                            } else if ( cell.row.original.integrado === "Sim") {
                              colorCell = "#97df99";
                            } else if ( cell.row.original.integrado === "Erro") {
                              colorCell = "#fe93a3";
                            }

                            style = {
                              background: colorCell,
                              fontWeight: "bold",
                            };
                          }

                          if (
                            cell.column.id === "distribuidorTRR" &&
                            cell.row.id.indexOf(".") !== -1
                          ) {
                            if (
                              cell.row.original.distribuidor_id ===
                              cell.row.original
                                .distribuidor_preferencia_id
                            ) {
                              colorCell = "#d2dce7";
                            }

                            style = {
                              background: colorCell,
                            };
                          }

                          let blink = false;

                          if (
                            cell.column.id === "preco" &&
                            cell.row.id.indexOf(".") !== -1
                          ) {
                            if (
                              cell.row.original?.blink &&
                              cell.row.original?.preco !== "0.0000"
                            ) {
                              blink = true;
                            }
                          }

                          return (
                            <td key={cell.id}  style={style}
                            className={blink ? "blink" : ""}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </td>
                          )
                        })}

                        {
                          // Acões
                          /* Valida se é uma cotação e nao o pedido */
                          row.id.indexOf(".") === -1 ? (
                            <td>
                              <OptionsList style={{position: 'inherit'}}>
                                <li>
                                  <OptionsIconPlaceholder
                                    Icon={ImCancelCircle}
                                    placeholder={"Cancelar Pedido"}
                                    onClick={() =>
                                      handleCancelOrdersLine(
                                        `${row.original.id_cotacao}-${row.original.status}-${row.original.id}`
                                      )
                                    }
                                    permission="pedido-cancelar[1]"
                                  />
                                </li>

                                  {row.original.status === "Aprovado" ||
                                      row.original.status === "Aguardando Faturamento" ||
                                      row.original.status === "Faturado" ? (
                                        <>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={AiOutlineMail}
                                              placeholder={"Reenviar E-mails"}
                                              onClick={() =>
                                                handleReenviarEmail(
                                                  `${row.original.id_cotacao}`
                                                )
                                              }
                                              loading={loadingEmail}
                                            />
                                          </li>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={iconEspelhoCliente}
                                              image={true}
                                              placeholder={"Espelho Cliente"}
                                              onClick={() =>
                                                handleDownloadCliente(
                                                  `${row.original.id}`
                                                )
                                              }
                                              loading={loadingEspelhoCliente}
                                            />
                                          </li>
                                          <li>
                                            <OptionsIconPlaceholder
                                              Icon={iconEspelhoDistribuidor}
                                              image={true}
                                              placeholder={"Espelho Distribuidor/TRR"}
                                              onClick={() =>
                                                handleDownloadDistribuidor(
                                                  `${row.original.id}`
                                                )
                                              }
                                              loading={loadingEspelhoDistribuidor}
                                            />
                                          </li>
                                        </>
                                      ) : (
                                        ""
                                      ) }

                                {/* integrado pedido */}
                                {row.original.status ===
                                    "Aprovado" || row.original.status ===
                                    "Aguardando Faturamento"  || row.original.status ===
                                    "Faturado" ? (
                                      !(row.original.integrado === "Sim") ? (
                                        <li>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="integrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "integrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      ) : (
                                        <li>
                                          <div></div>
                                          <OptionsIconPlaceholder
                                            Icon={FaSortAmountUp}
                                            placeholder={
                                              <FormattedMessage id="reintegrar" />
                                            }
                                            onClick={() =>
                                              handleIntegrateOrder(
                                                row.original,
                                                "reintegrar"
                                              )
                                            }
                                            loading={integrateButton}
                                            permission="pedido-integrar[1]"
                                          />
                                        </li>
                                      )
                                    ) : (
                                      <></>
                                    )}
                              </OptionsList>
                            </td>
                          ) : (
                            <td>
                              <OptionsList>
                                <li>
                                  <OptionsIconPlaceholder
                                    Icon={FiEye}
                                    placeholder={
                                      <FormattedMessage id="exibir" />
                                    }
                                    onClick={() => {
                                      return handleDisplay(row);
                                    }}
                                    permission="pedido-show[1]"
                                  />
                                </li>

                                {row.original.status === "Aprovado" ||
                                row.original.status === "Faturado" ||
                                row.original.status ===
                                  "Aguardando Faturamento" ||
                                row.original.status === "Reprovado" ||
                                row.original.status === "Cancelado" ||
                                row.original.status ===
                                  "Aguardando Aprovação" ||
                                row.original.statusCotacao ===
                                  "Cancelado" ? (
                                  <></>
                                ) : (
                                  <li>
                                    <OptionsIconPlaceholder
                                      Icon={FiEdit}
                                      placeholder={
                                        <FormattedMessage id="editar" />
                                      }
                                      onClick={() => handleEdit(row)}
                                      permission="pedido-update[1]"
                                    />
                                  </li>
                                )}

                                {row.original.status !== "Cotação" ? (
                                  <></>
                                ) : (
                                  <li>
                                    <OptionsIconPlaceholder
                                      Icon={FiTrash2}
                                      placeholder={"Cancelar Cotação"}
                                      onClick={() =>
                                        handleCancelCotacaoLine(
                                          row.original.id_cotacao
                                        )
                                      }
                                      permission="pedido-cancelar[1]"
                                    />
                                  </li>
                                )}
                              </OptionsList>
                            </td>
                          )
                        }
                      </tr>
                    )
                  })}
                </tbody>
              </table>

              {loadingListFilter ? (
                <ContentSpinner
                  style={{
                    zIndex: "-1",
                    height: "65vh",
                    width: "90%",
                    position: "fixed",
                  }}
                />
              ) : null}
            </main>
            <Paginate
              // handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              totalCotacao={totalCotacao}
              totalPedido={totalPedido}
              local={"painel"}
            />
          </>
        )}
      </Container>
      {showAction && (
        <ActionContainer>
          <header>
            <h4>
              <FormattedMessage id="acoes" />
            </h4>
            <FiXCircle onClick={() => setShowAction(!showAction)} />
          </header>
          <div>
            {(user.tipo_id === 1 ||
              user.permission.filter(
                (p) => p.namePermission === "pedido-cancelar[1]"
              ).length > 0) && (
              <>
                <ButtonIcon
                  Icon={ImCross}
                  backgroundColor={"#b50000"}
                  permission="pedido-cancelar[1]"
                  onClick={handleCancelOrders}
                />
                <label>Cancelar Pedido</label>
              </>
            )}
            {(user.tipo_id === 1 ||
              user.permission.filter(
                (p) => p.namePermission === "pedido-cancelar[1]"
              ).length > 0) && (
              <>
                <ButtonIcon
                  Icon={FaSortAmountUp}
                  backgroundColor={"#d4bb31"}
                  onClick={handleIntegrateMultiOrder}
                  loading={integrateMultiButtonLoading}
                />
                <label>Integrar</label>
              </>
            )}
          </div>
        </ActionContainer>
      )}

      {showLegends && (
        <ActionContainer>
          <header>
            <h4>
              Legendas (Indicação das Cores)
            </h4>
            <FiXCircle onClick={() => setShowLegends(!showLegends)} />
          </header>
          <div>

                <ButtonIcon
                  Icon={MdAttachMoney}
                  backgroundColor={"#32a932"}
                  disabled
                />
                <label style={{marginRight: "0!important", width: "195px"}}>Melhor preço referente ao pedido</label>

                <ButtonIcon
                  Icon={AiOutlineClockCircle}
                  backgroundColor={"#f33e3ec7"}
                />
                <label  style={{marginRight: "0!important", width: "210px"}}>Tempo limite para resposta (2 horas)</label>

                <ButtonIcon
                  Icon={MdFavorite}
                  backgroundColor={"#d2dce7"}
                />
                <label  style={{marginRight: "0!important", width: "195px"}}>Distribuidor/TRR de preferência</label>
          </div>
        </ActionContainer>
      )}

      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>

          <InputMaskDefault
            name="numero_pedido"
            style={{ borderColor: "#cccccc" }}
            label={"Número Pedido"}
            type="text"
            mask={"int15"}
            maxLength={15}
            onChange={(e) => setFilterNumeroPedido(e.target.value)}
            value={filterNumeroPedido}
          />

          <ListSelect
            name="status"
            labelPlaceholder={"Status Pedido"}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={statusOptions}
            onChange={(e) => {
              setFilterStatus(e);
            }}
            isClearable={true}
            value={filterStatus}
          />

          <p style={{marginBottom: 0, paddingLeft: 10, fontWeight: 'bold'}}>Data Criação (De - Até)</p>
          <DatePicker
            selectsRange={true}
            startDate={startDateCreate}
            endDate={endDateCreate}
            onChange={(update) => {
              setDateRange(update);
            }}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            withPortal
          />

          <InputLabel
            name="cliente"
            label={"Cliente"}
            style={{ borderColor: "#cccccc" }}
            type="text"
            onChange={(e) => setFilterCliente(e.target.value)}
            value={filterCliente}
          />

          <AsyncListSelect
            name="produto"
            labelPlaceholder={"Produto"}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            myOptions={"produtos"}
            onChange={(e) => {
              setFilterProduto(e);
            }}
            isClearable={true}
            value={filterProduto}
          />

          <InputLabel
            name="distribuidor"
            label={"Distribuidor/TRR"}
            style={{ borderColor: "#cccccc" }}
            type="text"
            onChange={(e) =>
              setFilterDistribuidorTRR(e.target.value)
            }
            value={filterDistribuidorTRR?.value}
          />

          <ListSelect
            name="cotacaoOriginal"
            labelPlaceholder={"Cotação Original"}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={notaServiço}
            onChange={(e) => {
              setFilterCotacaoOriginal(e);
            }}
            isClearable={true}
            value={filterCotacaoOriginal}
          />

          <InputLabel
            name="numero_pedido_cliente"
            label={"Número Pedido Cliete"}
            style={{ borderColor: "#cccccc" }}
            type="text"
            onChange={(e) =>
              setFilterNumeroPedidoCliente(e.target.value)
            }
            value={filterNumeroPedidoCliente}
          />

          <InputLabel
            name="numeroPedidoWizeo"
            label={"Carga Wizeo"}
            style={{ borderColor: "#cccccc" }}
            type="text"
            onChange={(e) => setFilterCargaWizeo(e.target.value)}
            value={filterCargaWizeo}
          />

          <ListSelect
            name="programados"
            labelPlaceholder={"Gerou Programação"}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={notaServiço}
            onChange={(e) => {
              setFilterProg(e);
            }}
            isClearable={true}
            value={filterProg}
          />

          <AsyncListSelect
            name="operador_id"
            placeholder={<FormattedMessage id="selecionar" />}
            labelPlaceholder={"Operador"}
            cacheOptions
            defaultOptions
            myOptions={"operadores"}
            onChange={(e) => {
              setFilterOperador(e);
            }}
            isClearable={true}
            value={filterOperador}
          />

          <InputMaskDefault
            name="prazo_pgt"
            label={"Prazo Pagamento"}
            style={{ borderColor: "#cccccc" }}
            mask={"int3"}
            type="text"
            onChange={(e) =>
              setFilterPrazoPagamento(e.target.value)
            }
            value={filterPrazoPagamento}
          />

          <p style={{marginBottom: 0, paddingLeft: 10, fontWeight: 'bold'}}>Data Entrega (De - Até)</p>
          <DatePicker
            selectsRange={true}
            startDate={startDateEntrega}
            endDate={endDateEntrega}
            onChange={(update) => {
              setEntregaRange(update);
            }}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            withPortal
          />

          <InputLabel
            name="cotacao"
            label={"Número Cotação"}
            style={{ borderColor: "#cccccc" }}
            type="text"
            onChange={(e) => setFilterCotacao(e.target.value)}
            value={filterCotacao}
          />

          <ListSelect
            name="status_cotacao"
            labelPlaceholder={"Status Cotação"}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={statusCotacao}
            onChange={(e) => {
              setFilterStatusCotacao(e);
            }}
            isClearable={true}
            value={filterStatusCotacao}
          />

          <ListSelect
            name="integrado"
            placeholder={<FormattedMessage id="selecionar" />}
            labelPlaceholder={"Integrado"}
            cacheOptions
            defaultOptions
            options={integradoOptions}
            onChange={(e) => {
              setFilterIntegrado(e);
            }}
            isClearable={true}
            value={filterIntegrado}
          />

          <ListSelect
            name="notaServiço"
            placeholder={<FormattedMessage id="selecionar" />}
            labelPlaceholder={"Nota Fiscal Serviço"}
            cacheOptions
            defaultOptions
            options={notaServiço}
            onChange={(e) => {
              setFilterNotaServiço(e);
            }}
            isClearable={true}
            value={filterNotaServiço}
          />

          <ListSelect
            name="notaDistribuidor"
            placeholder={<FormattedMessage id="selecionar" />}
            labelPlaceholder={"Nota Fiscal Distribuidor/TRR"}
            cacheOptions
            defaultOptions
            options={notaDistribuidor}
            onChange={(e) => {
              setFilterNotaDistribuidor(e);
            }}
            isClearable={true}
            value={filterNotaDitribuidor}
          />

          <ListSelect
            name="boleto"
            placeholder={<FormattedMessage id="selecionar" />}
            labelPlaceholder={"Boleto"}
            cacheOptions
            defaultOptions
            options={boleto}
            onChange={(e) => {
              setFilterBoleto(e);
            }}
            isClearable={true}
            value={filterBoleto}
          />
        </main>

          <footer>
            <Button
              onClick={() => handleFilter()}
              loading={filterButton}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(PainelList);
