import React, { useCallback, useEffect, useState, createRef } from "react";
import { useTable } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import {
  FiEdit,
  FiEye,
  FiTrash2,
  FiArrowLeft,
  FiFilter,
  FiPlus,
  FiXCircle,
} from "react-icons/fi";
import AsyncListSelect from "./../../../../../components/ListSelect/AsyncListSelect";

import swal from "sweetalert";

import { FormattedMessage, injectIntl } from "react-intl";
import { format, parseISO } from "date-fns";

import { Container, FilterContainer } from "./styles";
import api from "./../../../../../services/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import ButtonIcon from "./../../../../../components/ButtonIcon";
import Button from "./../../../../../components/ButtonDefault";
import ButtonImage from "./../../../../../components/ButtonImage";

import OptionsList from "./../../../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../../../components/OptionsList/OptionsIconPlaceholder";

import InputLabel from "./../../../../../components/InputLabel";
import InputMaskDefault from "./../../../../../components/InputMaskDefault";

import ListSelect from "./../../../../../components/ListSelect";

import ContentSpinner from "./../../../../../components/Spinner/Content";

import ExcelLogo from "./../../../../../assets/images/logo-excel.png";

import Paginate from "./../../../../../components/Paginate";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Simulation = ({ tipo, intl}) => {
  const [products, setProducts] = useState([]);

  const refExcel = createRef(null);

  const [clienteFilter, setClienteFilter] = useState("");
  const [produtoFilter, setProdutoFilter] = useState([]);
  const [DateCreateRange, setDateRange] = useState([null, null]);
  const [startDateCreate, endDateCreate] = DateCreateRange;

  const [loadingList, setLoadingList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  // Paginação
  const location = useLocation();
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [total, setTotal] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const history = useHistory();

  const desejaExcluirRegistro = intl.formatMessage({
    id: "desejaExcluirRegistro",
  });
  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  useEffect(() => {
    (async () => {

      registerLocale("ptBR", ptBR);

      const formattedDataDe =  moment().format("YYYY-MM-DD 00:00:00");
      const formattedDataAte =  moment().format("YYYY-MM-DD 23:59:59");

      const data = {
        data_inicial: formattedDataDe,
        data_final: formattedDataAte
      };

      try {
        const { data: response } = await api.post(`simulacao/filter`, data);

        const formattedList = response.data.map((item) => {
          const obj = {};
          console.log(item)

          Object.assign(obj, {
            id: item?.id,
            cliente: item?.cadastro?.cpf_cnpj?.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) + " - " + item?.cadastro?.razao_social,
            produto: item?.produto?.tipo_produto === "combustivel" ?   item?.produto?.descricao :  item?.produto?.nome,
            marca: item?.produto?.marca?.nome,
            categoria: item?.produto?.categoria?.nome,
            volume: parseInt(item?.quantidade/1000)?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            }).toString()?.replace(".", ","),
            prazo: item?.prazo,
            quantidade: (item?.quantidade)/1000,
            preco: item?.preco,
            taxa_administrativa: item?.taxa_administrativa,
            taxa_financeira: item?.taxa_financeira,
            frete: item?.frete,
            data: format(
              parseISO(item.updated_at),
              "dd/MM/yyyy HH:mm"
            ),
          });

          return obj;
        });

        setTotal(response?.total);
        setFirstPage(response?.first_page_url);
        setLastPage(response?.last_page);
        setLastPageUrl(response?.last_page_url);
        setNextPage(response?.next_page_url);
        setPrevPage(response?.prev_page_url);
        setCurrentPage(response?.current_page);
        setCountPage(() => {
          const arr = [];

          // response?.last_page

          const current_Page = response?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.last_page;

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber =  5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setProducts(formattedList);
        setLoadingList(false);

      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, [history]);


  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleDelete = useCallback(
    async (id) => {
      const willDelete = await swal({
        title: voceTemCerteza,
        text: desejaExcluirRegistro,
        icon: "warning",
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {
          await api.delete(`/simulacao/${id}`);
          setProducts(products.filter((product) => product.id !== id));
          toast.success(<FormattedMessage id="registroExcluidoComSucesso" />);
        } catch (err) {
          toast.error(<FormattedMessage id="erroExcluirRegistro" />);
        }
      }
    },
    [products, voceTemCerteza, desejaExcluirRegistro, opcaoSim, cancelar]
  );

  const handleFilter = useCallback(async () => {
    setLoadingButton(true);

    const formattedDataDe =  moment().format("YYYY-MM-DD 00:00:00");
    const formattedDataAte =  moment().format("YYYY-MM-DD 23:59:59");

    /* Data Criação */
    const dataDeCreate = DateCreateRange[0] === null ? formattedDataDe : moment(DateCreateRange[0]).format("YYYY-MM-DD 00:00:00");
    const dataAteCreate = DateCreateRange[1] === null ? formattedDataAte : moment(DateCreateRange[1]).format("YYYY-MM-DD 23:59:59");

    const data = {
      data_inicial: dataDeCreate,
      data_final: dataAteCreate,
      produto: parseInt(produtoFilter.value),
      cadastro: clienteFilter.value
    };

    try {
      const { data: response } = await api.post("/simulacao/filter", data);

      const formattedList = response.data.map((item) => {
        const obj = {};

        Object.assign(obj, {
          id: item?.id,
          cliente: item?.cadastro?.cpf_cnpj?.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ) + " - " + item?.cadastro?.razao_social,
          produto: item?.produto?.descricao,
          marca: '',
          categoria: "",
          volume: parseInt(item?.quantidade/1000)?.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          }).toString()?.replace(".", ","),
          prazo: item?.prazo,
          quantidade: (item?.quantidade)/1000,
          preco: item?.preco,
          taxa_administrativa: item?.taxa_administrativa,
          taxa_financeira: item?.taxa_financeira,
          frete: item?.frete
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setProducts(formattedList);
      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    } catch (error) {
      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    }
  }, [clienteFilter, produtoFilter, DateCreateRange]);

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        // {
        //   value: item?.codigo || "",
        //   style: {
        //     alignment: {
        //       wrapText: false,
        //       horizontal: "center",
        //     },
        //   },
        // },
      ];
    });

    return [
      {
        columns: [
          {
            title: "Código",
            width: { wpx: 90 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Descrição",
            width: { wpx: 220 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "NCM",
            width: { wpx: 190 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Status",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="cliente" />,
        accessor: "cliente",
      },
      {
        Header: <FormattedMessage id="marca" />,
        accessor: "marca",
      },
      {
        Header: <FormattedMessage id="categoria" />,
        accessor: "categoria",
      },
      {
        Header: <FormattedMessage id="produto" />,
        accessor: "produto",
      },
      {
        Header: <FormattedMessage id="volumeM2" />,
        accessor: "volume",
      },
      {
        Header: <FormattedMessage id="quantidade" />,
        accessor: "quantidade",
      },
      {
        Header: <FormattedMessage id="taxaAdministrativa" />,
        accessor: "taxa_administrativa",
      },
      {
        Header: <FormattedMessage id="taxaFinanceira" />,
        accessor: "taxa_financeira",
      },
      {
        Header: <FormattedMessage id="frete" />,
        accessor: "frete",
      },
      {
        Header: <FormattedMessage id="preco" />,
        accessor: "preco",
      },
      {
        Header: <FormattedMessage id="prazo" />,
        accessor: "prazo",
      },
      {
        Header: <FormattedMessage id="data" />,
        accessor: "data",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: products, initialState: {
    hiddenColumns: history.location.state?.tipo ? []  : ['marca', 'categoria']
  } });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(
    async (url) => {
      try {
        const { data: response } = await api.get(url);
        const formattedList = response.data.map((item) => {
          const obj = {};

          const formattedNCM =
            item.ncm.length > 1
              ? item.ncm.map((n, codigo, arr) => {
                  return `${n.codigo}${codigo !== arr.length - 1 ? ",ﾠ" : ""}`;
                })
              : item.ncm[0]?.codigo;

          Object.assign(obj, {
            // id: item.id,
          });

          return obj;
        });

        setTotal(response?.total);
        setFirstPage(response?.first_page_url);
        setLastPage(response?.last_page);
        setLastPageUrl(response?.last_page_url);
        setNextPage(response?.next_page_url);
        setPrevPage(response?.prev_page_url);
        setCurrentPage(response?.current_page);
        setCountPage(() => {
          const arr = [];

          // response?.last_page

          const current_Page = response?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.last_page;

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber =  5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setProducts(formattedList);
        setLoadingList(false);

        history.push(
          history.location.pathname + `?page=${response?.current_page}`
        );
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    },
    [history]
  );

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonIcon
              Icon={FiArrowLeft}
              backgroundColor={"#c8c8c8"}
              color={"#444"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              Simulação
            </h2>
          </nav>

          <div>
              <ButtonIcon
                Icon={FiFilter}
                backgroundColor={"#d4bb31"}
                placeholder={<FormattedMessage id="filtrar" />}
                onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
              />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Simulação"
              >
                <ExcelSheet
                  dataSet={formatExcelData(products)}
                  name="Simulação"
                />
              </ExcelFile>
              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    rows.map((row) => {
                      // Prepare the row for display

                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              );
                            })
                          }
                          <td>
                            <OptionsList>
                              <li>
                                <OptionsIconPlaceholder
                                  placeholder={
                                    <FormattedMessage id="excluir" />
                                  }
                                  Icon={FiTrash2}
                                  onClick={() => handleDelete(row.original.id)}
                                  permission={true}
                                />
                              </li>
                            </OptionsList>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
            />
          </>
        )}
      </Container>
      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <AsyncListSelect
              value={clienteFilter}
              myOptions={"clientes"}
              // isMulti
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="cliente" />}
              placeholder={<FormattedMessage id="selecionar" />}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              onChange={(e) => setClienteFilter(e)}
            />

            <AsyncListSelect
              value={produtoFilter}
              myOptions={"produtos"}
              // isMulti
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              onChange={(e) => setProdutoFilter(e)}
            />

            <p style={{marginBottom: 0, paddingLeft: 10, fontWeight: 'bold'}}>Data Criação (De - Até)</p>
            <DatePicker
            selectsRange={true}
            startDate={startDateCreate}
            endDate={endDateCreate}
            onChange={(update) => {
              setDateRange(update);
            }}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            withPortal
          />

          </main>

          <footer>
            <Button
              type="button"
              themeType="default"
              onClick={handleFilter}
              loading={loadingButton}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(Simulation);
